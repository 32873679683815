// @import './_vars.scss';

.form-wrapper-contact {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
  &.hide-form {
    display: none;
  }
  form {
    label {
      // display: flex;

    }
    .label {
      display: block;
    }
    input, textarea {
      width: 100%;
    }
    textarea {
      height: 10em
    }
    h2 {
      font-size: 1.5em;
      margin: 1em 0;
      // font-family: $deco;
      font-weight: 800;
      // text-transform: lowercase;
    }
    padding: 1em;
    // color: black;

    p {
      // font-family: Helvetica;
    }
    button {
      background: red;
      border: none;
      
      box-shadow: none;
      color: white;
      // color: $black;
      width: 100%;
    }
  }
}
