$sigFont: filson-soft, sans-serif;
.about-page {

  color: black;
  font-weight: 500;
  font-size: 0.8em;
  padding: 1.5em 0.5em;
  line-height: 1.5em;
  a {
    color: #e6001c;
    text-decoration: none;
    font-weight: 600;
  }
  // padding-bottom: 2em;
  h1.about-page-title {
    margin: 0.5em 0;
    font-family: $sigFont;
    font-weight: 600;
    font-size: 2.5em;
    text-align: center;
    color: black;
  }
  h2 {
    margin: 0.5em 0;
    font-family: $sigFont;
    font-weight: 600;
    font-size: 2em;
    text-align: left;
    color: black;
  }
  p {
    margin: 1.5em 0;
    clear: both;
    // line-height: 1.3em;
  }
  img {
    border: 2px solid black;
  }

  .post {
    max-width: 768px;
    margin: 0em auto;
    padding: 0 0.5em
  }
  .content {
    background: #f3f6f0;
    padding: 1em;
    border-radius: 0.5em;
  }
}

// .page-links {
//   display: none;
// }
// @media(min-width: 450px) {
//   .page-links {
//     display: block;
//   }
// }
// a.nav-page-link {
//   font-family: Fredoka One;
//   font-weight: 600;
//   margin-left: 1em;
//   color: #e6001c;
//   // text-decoration: none;
// }
