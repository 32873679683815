// @import './_vars.scss';

.form-wrapper-contact {
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  form {

    h2 {
      font-size: 1.5em;
      margin: 1em 0;
      // font-family: $deco;
      font-weight: 800;
      // text-transform: lowercase;
    }
    padding: 1em;
    // color: black;

    p {
      // font-family: Helvetica;
    }
    button {
      // background: $yellow;
      // color: $black;
      width: 5em;
    }
  }
}
