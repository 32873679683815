.pablitostacos {
  .site-header {
    display: flex;
    justify-content: center;
    padding-top: 1em;
    clip-path: polygon(0 0, 100% 0, 100% 96%, 0 100%);
    // background: black;
    .logo {
      width: 300px;
      transform: rotate(-10deg);
    }
  }
}
